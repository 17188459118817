import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { modifyVote } from "../DBStuff/FirebaseTest";
import { resultStringList } from "../Helpers";
import { Centerer } from "../Styles";
import { palatteOne } from "./ColorPalates";
import Graph, { GraphWidth } from "./Graph";
import VoteButtons from "./VoteButtons";

const Wrapper = styled.div`
  width: ${GraphWidth}px;
`;

const VoteText = styled.p`
  color: white;
  font-size: ${(props) => (props.small ? 14 : 25)}px;
  min-width: 300px;
`;

const ImgVoteArea = styled.div`
  display: flex;
  justify-content: center;
`;

const ProfileImageSize = 150;

//The plan with local should be:
//When the animal swithces, check local
//If undefined,we haven't voted yet:
//Set chosen index to -1 and userhas to false

//If -1, we voted but didn't keep it:
//Set chosen index to -1 and userHas to True

//If not negative one, we voted and kept it:
//Set chosen index to whatever it is. Set userhas to true.

//My question: why can't chosenIndex juse BE local storage and hasVoted just be a check on that??

export default function VoteSection({
  animal,
  voteType,
  profileImageSrc,
  text,
  baseVotes,
  nlInitialVote = null,
  colorPalatte,
  statsTitle,
  stats,
}) {
  const localStorageName = animal + "_" + voteType;
  // const [userHasVoted, setUserHasVoted] = useState(false);
  const [chosenIndex, setChosenIndex] = useState(-1);
  const [imageIsHovered, setImageIsHovered] = useState(false);
  useEffect(() => {
    var localVoteVal = localStorage.getItem(localStorageName);
    if (localVoteVal !== null) {
      // setUserHasVoted(true);
      setChosenIndex(parseInt(localVoteVal));
    } else {
      // setUserHasVoted(false);
      setChosenIndex(-1);
    }
  }, [animal]);

  function choiceChanged(newIndex) {
    var modArray = [0, 0, 0, 0, 0];
    if (chosenIndex !== -1) modArray[chosenIndex] = -1;
    if (newIndex !== -1) modArray[newIndex] = 1;
    //reverse it bc the db thinks of it backwards. my greatest failure
    // if (!userHasVoted) setUserHasVoted(true);
    setChosenIndex(newIndex);
    localStorage.setItem(localStorageName, newIndex);
    modifyVote(animal, voteType, modArray.reverse());
  }
  var baseVotesCopy = [...baseVotes];
  if (chosenIndex !== -1)
    baseVotesCopy[chosenIndex] = baseVotesCopy[chosenIndex] + 1;

  var voteCount = baseVotesCopy.reduce((agg, next) => agg + next, 0);
  var percentages = [];
  for (const ind in baseVotesCopy) {
    percentages.push(baseVotesCopy[ind] / voteCount);
  }

  const userHasVoted = true;
  return (
    <Wrapper>
      <Centerer>
        <Graph
          colorPalatte={colorPalatte}
          voteData={
            userHasVoted && !percentages.some((x) => isNaN(x))
              ? baseVotesCopy
              : [0, 0, 0, 0, 0]
          }
          percentages={
            userHasVoted && !percentages.some((x) => isNaN(x))
              ? percentages
              : [0, 0, 0, 0, 0]
          }
        />
      </Centerer>
      <div style={{ height: "30px" }} />
      <Centerer>
        <VoteText>{text}</VoteText>
      </Centerer>
      <Centerer>
        <ImgVoteArea>
          <img
            onMouseOver={() => setImageIsHovered(true)}
            onMouseLeave={() => setImageIsHovered(false)}
            style={{
              minWidth: ProfileImageSize + "px",
              minHeight: ProfileImageSize + "px",
              maxWidth: ProfileImageSize + "px",
              maxHeight: ProfileImageSize + "px",
              border: "2px solid white",
              objectFit: "cover",
            }}
            src={profileImageSrc}
          />
          <div style={{ width: "10px" }} />
          {imageIsHovered ? (
            <Stats
              statsTitle={statsTitle}
              stats={stats}
            />
          ) : null}
          <VoteButtons
            pastVote={chosenIndex}
            profileImageSize={ProfileImageSize + 4}
            colorPalatte={colorPalatte}
            choiceChangedCallback={choiceChanged}
          />
        </ImgVoteArea>
      </Centerer>
      <div style={{ height: "60px" }}>
        {nlInitialVote !== null ? (
          <Centerer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "350px",
              }}>
              <VoteText small>
                Northernlion initially said he'd{" "}
                {resultStringList[nlInitialVote]}.
              </VoteText>
            </div>
          </Centerer>
        ) : null}
      </div>
    </Wrapper>
  );
}

const StatsWrapper = styled.div`
  position: relative;
  background-color: black;
  border: 1px solid gray;
  color: white;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  z-index: 100000;
  margin-top: ${(props) => (props.you ? "40px" : "13px")};
`;

function Stats({ statsTitle, stats }) {
  return (
    <div style={{ position: "relative", width: "0px", height: "0px" }}>
      <div
        style={{
          position: "absolute",
          width: "250px",
          zIndex: "10000",
        }}>
        <StatsWrapper you={stats.length !== 3}>
          <p
            style={{
              margin: "5px 0 10px 0",
              fontSize: "20px",
              textDecoration: "underline",
            }}>
            {statsTitle}
          </p>
          {stats.map((stat) => (
            <p
              style={{ margin: "5px 0" }}
              key={stat}>
              {stat}
            </p>
          ))}
        </StatsWrapper>
      </div>
    </div>
  );
}
