import BearImage from "./AnimalImages/Bear.jpg";
import CrabImage from "./AnimalImages/Crab.jpg";
import CowImage from "./AnimalImages/Cow3.jpg";
import LobsterImage from "./AnimalImages/Lobster.jpg";
import AntImage from "./AnimalImages/Ant.jpg";
import AxolotlImage from "./AnimalImages/Axolotl.jpg";
import BlackbearImage from "./AnimalImages/Blackbear.jpg";
import CamelImage from "./AnimalImages/Camel.jpg";
import CassowaryImage from "./AnimalImages/Cassowary.jpg";
import CheetahImage from "./AnimalImages/Cheetah.jpg";
import ChimpanzeeImage from "./AnimalImages/Chimpanzee.jpg";
import ClownfishImage from "./AnimalImages/Clownfish.jpg";
import CrocodileImage from "./AnimalImages/Crocodile2.jpg";
import DeerImage from "./AnimalImages/Deer.jpg";
import DogImage from "./AnimalImages/Dog.jpg";
import DolphinImage from "./AnimalImages/Dolphin.jpg";
import DuckImage from "./AnimalImages/Duck.jpg";
import EagleImage from "./AnimalImages/Eagle.jpg";
import ElephantImage from "./AnimalImages/Elephant.jpg";
import FerretImage from "./AnimalImages/Ferret.jpg";
import FoxImage from "./AnimalImages/Fox.jpg";
import GiantsquidImage from "./AnimalImages/Giantsquid.jpg";
import GiraffeImage from "./AnimalImages/Giraffe.jpg";
import GoatImage from "./AnimalImages/Goat.jpg";
import GooseImage from "./AnimalImages/Goose.jpg";
import GorillaImage from "./AnimalImages/Gorilla.jpg";
import HippoImage from "./AnimalImages/Hippo.jpg";
import HorseImage from "./AnimalImages/Horse.jpg";
import HousecatImage from "./AnimalImages/Housecat.jpg";
import HumanImage from "./AnimalImages/Human.jpg";
import IguanaImage from "./AnimalImages/Iguana.jpg";
import JerboaImage from "./AnimalImages/Jerboa.jpg";
import KangarooImage from "./AnimalImages/Kangaroo.jpg";
import KomododragonImage from "./AnimalImages/Komododragon.jpg";
import LeopardsealImage from "./AnimalImages/Leopardseal.jpg";
import LionImage from "./AnimalImages/Lion.jpg";
import LlamaImage from "./AnimalImages/Llama.jpg";
import MeerkatImage from "./AnimalImages/Meerkat.jpg";
import MooseImage from "./AnimalImages/Moose.jpg";
import OctopusImage from "./AnimalImages/Octopus.jpg";
import OwlImage from "./AnimalImages/Owl.jpg";
import PandaImage from "./AnimalImages/Panda.jpg";
import ParrotImage from "./AnimalImages/Parrot.jpg";
import PeacockImage from "./AnimalImages/Peacock.jpg";
import PigImage from "./AnimalImages/Pig.jpg";
import PigeonImage from "./AnimalImages/Pigeon.jpg";
import PorcupineImage from "./AnimalImages/Porcupine.jpg";
import RacoonImage from "./AnimalImages/Racoon.jpg";
import RhinoImage from "./AnimalImages/Rhino.jpg";
import SeaturtleImage from "./AnimalImages/Seaturtle.jpg";
import SharkImage from "./AnimalImages/Shark.jpg";
import SheepImage from "./AnimalImages/Sheep.jpg";
import SlothImage from "./AnimalImages/Sloth.jpg";
import SnakeImage from "./AnimalImages/Snake.jpg";
import SpermwhaleImage from "./AnimalImages/Spermwhale.jpg";
import StarfishImage from "./AnimalImages/Starfish.jpg";
import TigerImage from "./AnimalImages/Tiger.jpg";
import WalrusImage from "./AnimalImages/Walrus.jpg";
import WolfImage from "./AnimalImages/Wolf.jpg";
import ZebraImage from "./AnimalImages/Zebra.jpg";
// import XXXX_ Image from "./AnimalImages/Crocodile.jpg";

export const animalImageMap = {
  Ant: AntImage,
  Axolotl: AxolotlImage,
  "Black bear": BlackbearImage,
  Camel: CamelImage,
  Cassowary: CassowaryImage,
  Cheetah: CheetahImage,
  Chimpanzee: ChimpanzeeImage,
  Clownfish: ClownfishImage,
  Cow: CowImage,
  Crab: CrabImage,
  Crocodile: CrocodileImage,
  Deer: DeerImage,
  Dog: DogImage,
  Dolphin: DolphinImage,
  Duck: DuckImage,
  Eagle: EagleImage,
  Elephant: ElephantImage,
  Ferret: FerretImage,
  Fox: FoxImage,
  "Giant squid": GiantsquidImage,
  Giraffe: GiraffeImage,
  Goat: GoatImage,
  Goose: GooseImage,
  Gorilla: GorillaImage,
  "Grizzly bear": BearImage,
  Hippo: HippoImage,
  Horse: HorseImage,
  Housecat: HousecatImage,
  Human: HumanImage,
  Iguana: IguanaImage,
  Jerboa: JerboaImage,
  Kangaroo: KangarooImage,
  "Komodo dragon": KomododragonImage,
  "Leopard seal": LeopardsealImage,
  Lion: LionImage,
  Llama: LlamaImage,
  Lobster: LobsterImage,
  Meerkat: MeerkatImage,
  Moose: MooseImage,
  Octopus: OctopusImage,
  Owl: OwlImage,
  Panda: PandaImage,
  Parrot: ParrotImage,
  Peacock: PeacockImage,
  Pig: PigImage,
  Pigeon: PigeonImage,
  Porcupine: PorcupineImage,
  Racoon: RacoonImage,
  Rhino: RhinoImage,
  "Sea turtle": SeaturtleImage,
  Shark: SharkImage,
  Sheep: SheepImage,
  Sloth: SlothImage,
  Snake: SnakeImage,
  "Sperm whale": SpermwhaleImage,
  Starfish: StarfishImage,
  Tiger: TigerImage,
  Walrus: WalrusImage,
  Wolf: WolfImage,
  Zebra: ZebraImage,
};

export const animalAttributionMap = {
  Ant: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@entomologista?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Cherre Bezerra Da Silva
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/ANT?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Axolotl: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@nathanguzman?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Nathan Guzman
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Axolotl?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  "Black bear": (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@pete_nuij?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Pete Nuij
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Black-bear?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Camel: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@yana_yuzvenko?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Yana Yuzvenko
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Camel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Cassowary: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@liefdevoorreizen?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Jesper van der Pol
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Cassowary?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Cheetah: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@ahmadgalal?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Ahmed Galal
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/cheetah?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Chimpanzee: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@mad_pikcels?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        anudeep mad
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Chimpanzee?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Clownfish: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@davidclode?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        David Clode
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Clownfish?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Cow: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@_jonasnordberg?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Jonas Nordberg
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/cow?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Crab: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@mackenziejcruz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Mackenzie Cruz
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/crab?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Crocodile: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/de/@lebalu?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Leon Pauleikhoff
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/crocodile?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Deer: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@laura_college?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Laura College
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/deer?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Dog: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@o5ky?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Oscar Sutton
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/dog?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Dolphin: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@bryteeyes3?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Ranae Smith
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/dolphin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Duck: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@ross_sokolovski?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Ross Sokolovski
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/duck?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Eagle: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@cadop?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Mathew Schwartz
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/eagle?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Elephant: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@bepnamanh?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Nam Anh
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/elephant?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Ferret: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@rohanchang?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Rohan Chang
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Ferret?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Fox: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@introspectivedsgn?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Erik Mclean
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/fox?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  "Giant squid": (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@edoronila?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Esperanza Doronila
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/calamari?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>{" "}
      (I couldn't find a giant squid photo so here's some calimari)
    </>
  ),
  Giraffe: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@andyjh07?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Andy Holmes
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/giraffe?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Goat: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@biancablah?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Bianca Ackermann
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Goat?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Goose: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@thevibrantmachine?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Rebecca Peterson-Hall
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Goose?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Gorilla: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@valentinjrl?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Valentin Jorel
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Gorilla?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  "Grizzly bear": (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/ja/@markbasarabvisuals?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Mark Basarab
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/bear?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Hippo: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@2anda_rss4?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Andreea Russu
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/hippo?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Horse: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@wildlittlethingsphoto?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Helena Lopes
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/horse?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Housecat: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@plhnk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Paul Hanaoka
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/cat?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Human: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@joelmott?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Joel Mott
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/silouette?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Iguana: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/es/@thetruth23?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Matthew Essman
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Iguana?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Jerboa: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@xander_crawley?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Alexander Crawley
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/mouse?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Kangaroo: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/ja/@bobzepplin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        William Christen
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Kangaroo?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  "Komodo dragon": (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/it/@altraz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        altraz
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/komodo-dragon?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  "Leopard seal": (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/de/@rojekilian?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Sarah Kilian
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Leopard-seal?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Lion: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@mikabr?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Mika Brandt
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/lion?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Llama: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@sebastiengoldberg?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Sébastien Goldberg
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/LLama?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Lobster: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@anton_ahlberg101?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Anton Ahlberg
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/lobster?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Meerkat: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@pizbern?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Pauline Bernfeld
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Meerkat?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Moose: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@stp_com?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Tim Peterson
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/moose?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Octopus: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@kmitchhodge?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        K. Mitch Hodge
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Octopus?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Owl: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@ronan18?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Ronan Furuta
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/owl?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Panda: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@jaywennington?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Jay Wennington
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/panda?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Parrot: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@kevinmueller?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Kevin Mueller
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Parrot?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Peacock: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@trommelkopf?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Steve Harvey
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/peacock?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Pig: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@debrupas?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Pascal Debrunner
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Pig?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Pigeon: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@maurotandoi?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Mauro Tandoi
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Pigeon?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Porcupine: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@pete_nuij?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Pete Nuij
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Porcupine?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Racoon: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@albertstoynov?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Albert Stoynov
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Racoon?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Rhino: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/ko/@nicolewreyford?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Nicole Wreyford
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Rhino?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  "Sea turtle": (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@olgaga?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Olga ga
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Sea-turtle?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Shark: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@geerald?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Gerald Schömbs
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/shark?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Sheep: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@yoonbae81?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Yoonbae Cho
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Sheep?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Sloth: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@javier365?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Javier Mazzeo
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Sloth?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Snake: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@sagar_paranjape_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Sagar Paranjape
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/snake?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  "Sperm whale": (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@seijiseiji?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Seiji Seiji
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Sperm-whale?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Starfish: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@agoraabierta?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Clara Cordero
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Starfish?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Tiger: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@_rishabhpandoh_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Rishabh Pandoh
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/tiger?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Walrus: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@theshuttervision?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Jonathan Cooper
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Walrus?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Wolf: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@nineteen?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Andrew Ly
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/images/animals/wolf?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
  Zebra: (
    <>
      Photo by{" "}
      <a
        target='_blank'
        href='https://unsplash.com/@j_zhao?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Jason Zhao
      </a>{" "}
      on{" "}
      <a
        target='_blank'
        href='https://unsplash.com/s/photos/Zebra?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>
        Unsplash
      </a>
    </>
  ),
};
