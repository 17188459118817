import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components/macro";
import FAQIcon from "../Images/BlackWhiteFAQIcon.png";
import MapIcon from "../Images/WhiteMapIcon.png";
import RefreshIcon from "../Images/WhiteRefreshIcon.png";
import AnimalMap from "./AnimalMap";
import FAQPage from "./FAQPage";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  border: 1px white;
  border-style: none none solid none;
  cursor: pointer;
  padding: 5px;
`;

const Icon = styled.img`
  height: 32px;
`;

const Navigator = styled.div``;

const Descriptor = styled.p`
  color: white;
`;

const space = "30px";

export default function Header({}) {
  const [faqIsOpen, setFAQIsOpen] = useState(false);
  const [mapIsOpen, setMapIsOpen] = useState(false);
  const helpCode = 72;
  const refreshCode = 82;
  const mapCode = 77;
  useEffect(() => {
    function handleKeyDown(e) {
      // if (e.keyCode === (isLeft ? leftKeyCode : rightKeyCode))
      switch (e.keyCode) {
        case helpCode:
          setFAQIsOpen(!faqIsOpen);
          break;
        case mapCode:
          setMapIsOpen(!mapIsOpen);
          break;
        case refreshCode:
          window.location = "/";
          break;
        default:
          break;
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [faqIsOpen, mapIsOpen]);
  return (
    <Wrapper>
      <Icon
        src={FAQIcon}
        onMouseDown={() => setFAQIsOpen(true)}
      />
      <div style={{ width: space }} />
      <a href='/'>
        <Icon src={RefreshIcon} />
      </a>
      <div style={{ width: space }} />
      <Icon
        src={MapIcon}
        onMouseDown={() => setMapIsOpen(true)}
      />

      {faqIsOpen ? (
        <FAQPage closedCallback={() => setFAQIsOpen(false)} />
      ) : null}
      {mapIsOpen ? (
        <AnimalMap closedCallback={() => setMapIsOpen(false)} />
      ) : null}
    </Wrapper>
  );
}
