export const palatteTwo = [
  "#EFBDEB",
  "#B68CB8",
  "#6461A0",
  "#314CB6",
  "#0A81D1",
];
export const palatteOne = [
  "#FFCAB1",
  "#ECDCB0",
  "#C1D7AE",
  "#8CC084",
  "#759EB8",
];
export const palatteThree = [
  "#966B9D",
  "#C98686",
  "#F2B880",
  "#FFF4EC",
  "#3f6c51",
];
