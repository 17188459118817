import "./App.css";
import MainPage from "./Components/MainPage";
import CaroselPage from "./Components/CaroselPage";
import Header from "./Components/Header";

function App() {
  return (
    <div className='App'>
      {/* <MainPage /> */}
      <Header />
      <CaroselPage />
    </div>
  );
}

export default App;
