import React, { useState } from "react";
import styled from "styled-components/macro";
import { Tooltip } from "react-tooltip";

const sizeModder = 0.5;

const lineColor = "gray";
const TierBlockHeight = 60 * sizeModder;
const TotalGraphHeight = TierBlockHeight * 5;
const ValueBarWidth = 50 * sizeModder;
const ValueBarMargin = 30 * sizeModder;
const ValueBarSeperation = ValueBarWidth + ValueBarMargin;
const ValueBarTextHeight = 30 * sizeModder;
const ValueBarDividerHeight = 10 * sizeModder;
export const GraphWidth = ValueBarMargin + ValueBarSeperation * 5;
const Wrapper = styled.div`
  position: relative;
`;

const QuestionMarks = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  color: gray;
  font-size: 25px;
`;

//Percentages also communicates the info of whether or not it's revlealed, would go from 0's to something other than 0s
export default function Graph({ colorPalatte, percentages, voteData }) {
  return (
    <Wrapper>
      <Tier
        numb='100%'
        noTop
      />
      <Tier numb='80%' />
      <Tier numb='60%' />
      <Tier numb='40%' />
      <Tier numb='20%' />
      <Tier numb='0%' />
      <ValueBar
        left={ValueBarMargin}
        percentage={percentages[0]}
        votes={voteData[0]}
        barColor={colorPalatte[0]}>
        Easy
        <br />
        win
      </ValueBar>
      <ValueBar
        left={ValueBarMargin + ValueBarSeperation * 1}
        percentage={percentages[1]}
        votes={voteData[1]}
        barColor={colorPalatte[1]}>
        Likely
        <br />
        win
      </ValueBar>
      <ValueBar
        left={ValueBarMargin + ValueBarSeperation * 2}
        percentage={percentages[2]}
        votes={voteData[2]}
        barColor={colorPalatte[2]}>
        50/50
      </ValueBar>
      <ValueBar
        left={ValueBarMargin + ValueBarSeperation * 3}
        percentage={percentages[3]}
        votes={voteData[3]}
        barColor={colorPalatte[3]}>
        Likely
        <br />
        loss
      </ValueBar>
      <ValueBar
        left={ValueBarMargin + ValueBarSeperation * 4}
        percentage={percentages[4]}
        votes={voteData[4]}
        barColor={colorPalatte[4]}>
        Easy
        <br />
        loss
      </ValueBar>
    </Wrapper>
  );
}

const TierWrapper = styled.div`
  display: flex;
  height: ${TierBlockHeight}px;
`;

const TierBlock = styled.div`
  width: ${GraphWidth}px;
  border: 1px ${lineColor};
  border-style: none none ${(props) => (props.noTop ? "none" : "solid")}
    ${(props) => (props.noTop ? "none" : "solid")};
`;

const Text = styled.p`
  color: ${lineColor};
  width: 50px;
  height: fit-content;
  position: absolute;
  bottom: -8px;
  margin: 0px;
  text-align: right;
  left: -60px;
`;

const DescriptorText = styled.p`
    color ${lineColor};
    color: white;
    margin: 0px;
    height: ${ValueBarTextHeight}px;
    width: ${ValueBarWidth}px;
    font-size: 12px;
`;

function Tier({ numb, noTop = false }) {
  return (
    <TierWrapper>
      <div style={{ position: "relative" }}>
        <Text>{numb}</Text>
      </div>
      <TierBlock noTop={noTop} />
    </TierWrapper>
  );
}

const ValueBarWrapper = styled.div`
  position: absolute;
  bottom: ${1 + -1 * (ValueBarDividerHeight + ValueBarTextHeight)}px;
  left: ${(props) => props.left}px;
`;

const ValueBarBlock = styled.div`
  background-color: ${(props) => props.barColor};
  width: ${ValueBarWidth}px;
  height: ${(props) => Math.max(props.percentage * TotalGraphHeight - 1, 1)}px;
  transition: height 1000ms;
  margin: auto;
`;

const BarData = styled.p`
  background-color: black;
  color: gray;
  padding: 5px;
  border-radius: 6px;
  border: 1px solid gray;
  // margin-left: ${ValueBarWidth + 10}px;
  z-index: 10;
  font-size: 18px;
  width: fit-content;
`;

function ValueBar({ children, left, percentage, votes, barColor }) {
  const [hovered, setHovered] = useState(false);
  return (
    <ValueBarWrapper left={left}>
      {hovered ? (
        <div
          style={{
            position: "absolute",
            width: "300px",
            left: ValueBarWidth + 5 + "px",
            zIndex: 10,
          }}>
          <BarData>
            {votes} vote{votes === 1 ? "" : "s"} (
            {Math.round(percentage * 10000) / 100}
            %)
          </BarData>
        </div>
      ) : null}
      <ValueBarBlock
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className='bar-anchor'
        percentage={percentage}
        barColor={barColor}
      />
      <div style={{ height: ValueBarDividerHeight + "px" }} />
      <DescriptorText>{children}</DescriptorText>
    </ValueBarWrapper>
  );
}
