// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
import {
  collection,
  addDoc,
  setDoc,
  doc,
  getDoc,
  runTransaction,
  getDocs,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, signInAnonymously } from "firebase/auth";
//   const {
//   initializeAppCheck,
//   ReCaptchaV3Provider,
// } = require("firebase/app-check");

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_0VmCiWGoyo5QRHCJrN3Yas9Ca2ANLr0",
  authDomain: "nl-vs-d5227.firebaseapp.com",
  projectId: "nl-vs-d5227",
  storageBucket: "nl-vs-d5227.appspot.com",
  messagingSenderId: "52962879870",
  appId: "1:52962879870:web:63461584a4b4fad2beab2f",
  measurementId: "G-Y6CCECXQYH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

var isAuthed = false;

const auth = getAuth();
signInAnonymously(auth)
  .then(() => {
    isAuthed = true;
    // Signed in..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6LfLYk8lAAAAANF3v38x4uShHO7GnSxM6cfBIOq9"),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
function makeNLInitialArray(nlVote) {
  var arr = [0, 0, 0, 0, 0];
  arr[nlVote] += 1;
  return arr;
}

export async function addAnimal(name, nlVote) {
  await setDoc(doc(db, "VoteData", name), {
    youVotes: makeNLInitialArray(nlVote),
    nlVotes: makeNLInitialArray(nlVote),
    shaqVotes: [0, 0, 0, 0, 0],
    nlInitialVote: nlVote,
  });
}

export async function GetVotes(animalName) {
  try {
    while (!isAuthed) {
      await new Promise((r) => setTimeout(r, 200));
    }
    const docSnap = await getDoc(doc(db, "VoteData", animalName));
    if (docSnap.exists()) {
      const data = docSnap.data();
      return {
        nlInitialVote: data.nlInitialVote,
        youVotes: data.youVotes.reverse(),
        nlVotes: data.nlVotes.reverse(),
        shaqVotes: data.shaqVotes.reverse(),
      };
    }
  } catch (e) {}
  return null;
}

export async function getAllAnimalNames() {
  const querySnapshot = await getDocs(collection(db, "VoteData"));
  var names = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    names.push(doc.id);
  });
}

//Below is wild west of ex who-would work

//voteMod would be something like [1, 0], [1, -1], [-1, 1]. The latter would be switching from having voted left to voting right
//voteType is 0, 1, 2
export async function modifyVote(animal, voteType, voteMod) {
  var arrName =
    voteType === 0 ? "youVotes" : voteType === 1 ? "nlVotes" : "shaqVotes";
  try {
    await runTransaction(db, async (transaction) => {
      const docRef = doc(db, "VoteData", animal);
      const docSnap = await transaction.get(docRef);
      if (!docSnap.exists()) {
        throw "Document does not exist!";
      }
      var oldVotes = docSnap.data()[arrName];
      for (const ind in oldVotes) oldVotes[ind] += voteMod[ind];
      var updateDict = {};
      updateDict[arrName] = oldVotes;
      transaction.update(docRef, updateDict);
    });
  } catch (e) {}
}
