import React from "react";
import styled from "styled-components/macro";
import { Portal } from "react-portal";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.55);
`;

//Obvi fuc w this later
const ChildrenWrapper = styled.div`
  background-color: lightgrey;
  border: 5px solid white;
  padding: 5vh 5vw;
  max-width: 75vw;
  max-height: 75vh;
  overflow: scroll;
`;

export default function Popover({ children, closedCallback }) {
  return (
    <Portal>
      <Wrapper onMouseDown={closedCallback}>
        <ChildrenWrapper onMouseDown={(e) => e.stopPropagation()}>
          {children}
        </ChildrenWrapper>
      </Wrapper>
    </Portal>
  );
}
