import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { palatteOne, palatteThree, palatteTwo } from "./ColorPalates";
import { GetVotes } from "../DBStuff/FirebaseTest";
import Carosel from "./Carosel";
import VoteSection from "./VoteSection";
import NL1 from "../Images/NL1.png";
import NL2 from "../Images/NL2.png";
import NL3 from "../Images/NL3.png";
import NL4 from "../Images/NL4.png";
import NL5 from "../Images/NL5.png";
import NL6 from "../Images/NL6.png";
import NL7 from "../Images/NL7.png";
import NL8 from "../Images/NL8.png";
import MirrorImage from "../Images/IWantYou.webp";
import ShaqImage from "../Images/Shaq.png";
import { getRandomInt, shuffle } from "../Helpers";
import { animalAttributionMap, animalImageMap } from "../animalImagesMap";

const Wrapper = styled.div``;

const VoteWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    display: block;
  }
`;

const VoteMargin = "220px";

export function getAnimalUrlQuery() {
  const url = window.location.href;
  const urlParams = new URL(url).searchParams;
  const animal = urlParams.get("Animal");
  return animal;
}

export default function CaroselPage({}) {
  const nlImages = [NL1, NL2, NL3, NL4, NL5, NL6, NL7, NL8];
  //Animal list has the animals sort of meta data, their name, image, and attribution
  const [animalList, setAnimalList] = useState(null);
  //The cache has their voting data
  const [animalDataCache, setAnimalDataCache] = useState({});
  //Index of where we are in the (randomized) animal list
  const [currentAnimalIndex, setCurrentAnimalIndex] = useState(-1);
  //Animal data is the voting data, set in a useEffect. Can't just be a const because relies on data fetching
  const [currentAnimalData, setCurrentAnimalData] = useState(null);
  //U know what it is. It's in a state thing not just assinged on render so it isn't swapping around.
  const [nlImageIndex, setNLImageIndex] = useState(getRandomInt(8));

  const currentAnimal =
    currentAnimalIndex !== -1 && animalList !== null
      ? animalList[currentAnimalIndex]
      : 0;

  function updateAnimalDataForLocalStorage(animal, animalData) {
    function updateForValue(arr, type) {
      const value = localStorage.getItem(animal + "_" + type);
      if (value !== null) {
        arr[parseInt(value)]--;
      }
      return arr;
    }
    animalData.youVotes = updateForValue(animalData.youVotes, "0");
    animalData.nlVotes = updateForValue(animalData.nlVotes, "1");
    animalData.shaqVotes = updateForValue(animalData.shaqVotes, "2");
  }

  //First checks the cache for the animal, if its there return the cached value. If not, fetch it and cache it then return it
  async function getAnimalData(animalName) {
    if (!(animalName in animalDataCache)) {
      const animalData = await GetVotes(animalName);
      var copy = { ...animalDataCache };
      copy[animalName] = animalData;
      updateAnimalDataForLocalStorage(animalName, animalData);
      setAnimalDataCache(copy);
      return animalData;
    }
    return animalDataCache[animalName];
  }

  function updateAnimalIndex(isLeft) {
    var target = currentAnimalIndex + (isLeft ? -1 : 1);
    if (target >= animalList.length) target = 0;
    else if (target < 0) target = animalList.length - 1;
    setCurrentAnimalIndex(target);
  }

  function initalizeAnimalList() {
    var animalsWithImages = [];

    for (const animalImagePair of Object.entries(animalImageMap)) {
      if (
        animalImagePair[1] !== null
        //  && ["Ant", "Axolotl"].includes(animalImagePair[0])
      ) {
        animalsWithImages.push({
          animalName: animalImagePair[0],
          animalImage: animalImagePair[1],
          animalAttribution: animalAttributionMap[animalImagePair[0]],
        });
      }
    }
    shuffle(animalsWithImages);
    return animalsWithImages;
  }

  useEffect(() => {
    const animalQuery = getAnimalUrlQuery();
    var foundAnimalQueryIndex = -1;
    var createdAnimalList = initalizeAnimalList();
    for (const ind in createdAnimalList) {
      if (createdAnimalList[ind].animalName === animalQuery)
        foundAnimalQueryIndex = parseInt(ind);
    }
    setAnimalList(createdAnimalList);
    setCurrentAnimalIndex(
      foundAnimalQueryIndex !== -1 ? foundAnimalQueryIndex : 0
    );
  }, []);

  useEffect(() => {
    async function getData() {
      const leftOfCurrentAnimalIndex = getInboundsIndex(currentAnimalIndex - 1);
      const rightOfCurrentAnimalIndex = getInboundsIndex(
        currentAnimalIndex + 1
      );
      const leftAnimalName = animalList[leftOfCurrentAnimalIndex].animalName;
      const centerAnimalName = animalList[currentAnimalIndex].animalName;
      const rightAnimalName = animalList[rightOfCurrentAnimalIndex].animalName;

      getAnimalData(leftAnimalName);
      getAnimalData(rightAnimalName);
      const targetAnimalData = await getAnimalData(centerAnimalName);

      setCurrentAnimalData({
        ...targetAnimalData,
        animalName: centerAnimalName,
      });
      setNLImageIndex(getRandomInt(8));
    }
    if (currentAnimalIndex !== -1) getData();
  }, [currentAnimalIndex]);

  if (!currentAnimalData) return;

  function getInboundsIndex(target) {
    if (target >= animalList.length) return 0;
    if (target < 0) return animalList.length - 1;
    return target;
  }

  var queuedImages = [];
  for (var i = 1; i < 5; i++) {
    queuedImages.push(
      animalList[getInboundsIndex(currentAnimalIndex - i)].animalImage
    );
    queuedImages.push(
      animalList[getInboundsIndex(currentAnimalIndex + i)].animalImage
    );
  }

  return (
    <Wrapper>
      <Carosel
        leftCallback={() => updateAnimalIndex(true)}
        rightCallback={() => updateAnimalIndex(false)}
        animalName={currentAnimal.animalName}
        imgSrc={currentAnimal.animalImage}
        queuedImages={queuedImages}
        attribution={currentAnimal.animalAttribution}
      />
      <VoteWrapper>
        <VoteSection
          voteType={1}
          animal={currentAnimalData.animalName}
          baseVotes={currentAnimalData.nlVotes}
          nlInitialVote={currentAnimalData.nlInitialVote}
          profileImageSrc={nlImages[nlImageIndex]}
          text='Northernlion would:'
          colorPalatte={palatteTwo}
          statsTitle='Northernlion'
          stats={[
            "-I think 5'10 (has claimed 7'7)",
            "-Avid pelaton user",
            "-Dad strength",
          ]}
        />
        <div style={{ width: VoteMargin }} />

        <VoteSection
          voteType={0}
          animal={currentAnimalData.animalName}
          baseVotes={currentAnimalData.youVotes}
          profileImageSrc={MirrorImage}
          text='You would:'
          statsTitle='You'
          stats={[
            "-Only you can know yourself",
            "-Probably you're pretty chill though",
          ]}
          colorPalatte={palatteOne}
        />
        <div style={{ width: VoteMargin }} />
        <VoteSection
          voteType={2}
          animal={currentAnimalData.animalName}
          baseVotes={currentAnimalData.shaqVotes}
          profileImageSrc={ShaqImage}
          text='29 year old Shaq would:'
          colorPalatte={palatteThree}
          statsTitle='Shaq'
          stats={["-7'1", "-Agile like a gazelle", "-Broke two NBA backboards"]}
        />
      </VoteWrapper>
    </Wrapper>
  );
}
