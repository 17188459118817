import styled from "styled-components/macro";

export const AnimalWidth = 720;

export const StrengthWidth = 200;
export const UnexpandedStrengthHeight = 20;
export const ExpandedStrengthHeight = 90;
export const MenuLinesHeight = 40;
export const StrengthBorderSize = 2;
export const VoterHeight = 55;
export const PercentageBarHeight = 10;

export const detailHeight = 20;
export const detailTopMargin = 5;
export const BarBackground = "#c7c7c7";
export const AnimalFightBorder = "3px black";
export const VoterBorderWidth = 2;
export const ChoosableAnimalSize = 110;

function getTextShadow(borderStrength, borderColor) {
  return `-${borderStrength} 0 ${borderColor}, 0 ${borderStrength} ${borderColor}, ${borderStrength} 0 ${borderColor}, 0 -${borderStrength} ${borderColor}`;
}

export const Title = styled.p`
  font-size: 50px;
  font-weight: bold;
  text-decoration: underline;
  margin: 25px auto;
`;

export const BlackBorderedText = styled.p`
  text-shadow: ${(props) =>
    getTextShadow(props.borderStrength, props.borderColor)};

  color: ${(props) => (props.color ? props.color : "white")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};
  width: fit-content;
  margin: 0;
  text-align: ${(props) => (props.dontCenter ? "left" : "center")};
`;

export const BlueP = styled.p`
  border: 2px solid blue;
  margin: 0px 5px;
  height: fit-content;
  cursor: pointer;
`;

export const Centerer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 100vw;
  }
`;

export const SubmitButton = styled.div`
  color: black;
  border-radius: 5px;
  border: 2px solid red;
  padding: 10px;
  width: fit-content;
  cursor: pointer;
`;

export const Error = styled.p`
  color: red;
`;

export function TabA({ children, href }) {
  return (
    <a
      target='_blank'
      href={href}>
      {children}
    </a>
  );
}
