import React from "react";
import styled from "styled-components/macro";
import { Title } from "./FAQPage";
import Popover from "./Popover";
import { animalImageMap } from "../animalImagesMap";
import { Centerer } from "../Styles";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: scroll;
`;

const AnimalList = styled.div`
  > a {
    display: block;
    @media screen and (max-width: 1000px) {
      font-size: 15px; /* The width is 100%, when the viewport is 800px or smaller */
    }
  }
`;

export default function AnimalMap({ closedCallback }) {
  var possibleAnimals = [];

  for (const animalImagePair of Object.entries(animalImageMap)) {
    if (animalImagePair[1] !== null) possibleAnimals.push(animalImagePair[0]);
  }

  const maxLength = 15;
  var animalLists = [[]];

  var listInd = 0;
  for (const ind in possibleAnimals) {
    var currList = animalLists[listInd];
    const animalName = possibleAnimals[ind];
    if (currList.length >= maxLength) {
      listInd++;
      animalLists.push([]);
      currList = animalLists[listInd];
    }
    currList.push(
      <a
        href={"/?Animal=" + animalName}
        style={{ margin: "5px 5px", color: "blue" }}
        key={animalName}>
        {animalName}
      </a>
    );
  }

  return (
    <Popover closedCallback={closedCallback}>
      <Centerer>
        <Title>Go to a specific animal:</Title>
      </Centerer>
      <Wrapper>
        {animalLists.map((x, ind) => (
          <AnimalList key={ind}>{x}</AnimalList>
        ))}
      </Wrapper>
    </Popover>
  );
}
