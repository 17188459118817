import React from "react";
import styled from "styled-components/macro";
import { TabA } from "../Styles";
import Popover from "./Popover";

const Wrapper = styled.div`
  > p {
    text-align: center;
  }
`;

export const Title = styled.p`
  font-size: 50px;
  @media screen and (max-width: 800px) {
    font-size: 40px; /* The width is 100%, when the viewport is 800px or smaller */
  }
  text-decoration: underline;
  font-weight: bold;
  color: black;
  margin: 1vh 0;
`;

export const Subheader = styled.p`
  font-size: 30px;
  color: black;
  margin: 2vh 0;
  text-decoration: underline;
`;
export const Description = styled.p`
  font-size: 20px;
  color: black;
  margin: 1vh 0;
`;

export default function FAQPage({ closedCallback }) {
  return (
    <Popover closedCallback={closedCallback}>
      <Wrapper>
        <Title>FAQs</Title>
        <Subheader>What is this?</Subheader>
        <Description>
          I got the idea for this page from a video by{" "}
          <span>
            <TabA href='https://www.twitch.tv/northernlion'>Northernlion</TabA>
          </span>
          . He went through and{" "}
          <span>
            <TabA href='https://www.youtube.com/watch?v=8W6d9DucRuo&ab_channel=Northernlion'>
              ranked
            </TabA>
          </span>{" "}
          a{" "}
          <span>
            <TabA href='https://www.youtube.com/watch?v=hu5jcm94TE8&ab_channel=Northernlion'>
              bunch
            </TabA>
          </span>{" "}
          of animals by if he could take them in a fight, and now you can too!
        </Description>
        <Subheader>But like... Why?</Subheader>
        <Description>Seemed fun!</Description>
        <Subheader>Why these animals?</Subheader>
        <Description>
          These are the animals he ranked in his two videos so that's what we
          got.
        </Description>
        <Subheader>What's the situation the fight is happening in?</Subheader>
        <Description>
          I would say it's whatever you want. Some rules I think make sense:
        </Description>
        <Description>
          Both fighters want to defeat the other. There's no fleeing or waiting
          to outlast the opponent.
        </Description>
        <Description>
          I think of the arena as a big empty warehouse with no props or tools.
        </Description>
        <Description>
          If it's a purely aquatic animal (eg a shark) the warehouse is filled
          with water with some room to breathe at the top.
        </Description>
        <Description>
          Really though it's all made up, picture it however you want! You could
          also look at NL's videos to use his criteria.
        </Description>
      </Wrapper>
    </Popover>
  );
}
