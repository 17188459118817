//Works by comparing elemnts of array with === so wants those elements to be fundamental or whatever
export function ArraysAreEqual(a, b) {
  if (a.length !== b.length) return false;
  for (var i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function stringBetweenStrings(startStr, endStr, str) {
  const pos = str.indexOf(startStr) + startStr.length;
  return str.substring(pos, str.indexOf(endStr, pos));
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function GramsToPounds(numb) {
  return 0.0022046 * numb;
}
export function MMsToFeet(numb) {
  return 0.00328084 * numb;
}
export function DaysToYears(numb) {
  return 0.00273973 * numb;
}

export function OZsToPounds(numb) {
  return 0.0625 * numb;
}

//Wants an array of arrays
export function makeUrlQueries(queries) {
  if (queries.length === 0) return "";
  var qString = "?";
  for (const query of queries) {
    qString += query[0] + "=" + query[1] + "&";
  }
  return qString;
}

export function getEditorUrlQueries() {
  const url = window.location.href;
  const urlParams = new URL(url).searchParams;
  const editor = urlParams.get("editor");
  const username = urlParams.get("username");
  const token = urlParams.get("token");
  return { editor, username, token };
}

//Doesn't actually check to see if you're editor on the backend, just looks at the form of your args
//Fine because even if we show them the editor page it doesn't matter, the edit would get blocked w/o a valid username token match
export function showEditorPage() {
  const queries = getEditorUrlQueries();
  return (
    queries.editor === "true" &&
    queries.username !== "" &&
    queries.token.length === 36
  );
}

export const resultStringList = [
  "lose easily",
  "probably lose",
  "have a 50-50 shot",
  "probably win",
  "win easily",
];

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
