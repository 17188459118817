import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ProfileImageSize } from "./VoteSection";

const Wrapper = styled.div``;

const Bar = styled.div`
  width: ${(props) => 200 - (props.selected ? 6 : 0)}px;
  background-color: ${(props) => props.backgroundColor};
  color: black;
  height: ${(props) => props.profileImageSize / 5 - (props.selected ? 6 : 0)}px;
  display: flex;
  align-items: center;
  border: 3px ${(props) => (props.selected ? "solid" : "none")} white;
  opacity: ${(props) => (props.selected ? "1" : ".65")};
  cursor: pointer;
`;

const Text = styled.p`
  text-align: left;
  height: 100%;
  vertical-align: center;
  height: fit-content;
  margin-left: 5px;
  font-size: 18px;
`;

export default function VoteButtons({
  pastVote,
  profileImageSize,
  colorPalatte,
  choiceChangedCallback,
}) {
  const [index, setIndex] = useState(pastVote);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  function updateIndex(newIndex) {
    setIndex(newIndex);
    choiceChangedCallback(newIndex);
  }
  const [children, setChildren] = useState([]);

  useEffect(() => {
    if (index !== pastVote) setIndex(pastVote);
  }, [pastVote]);
  useEffect(() => {
    var toRender = [];
    for (var ind in texts) {
      ind = parseInt(ind);
      const copyInd = ind;
      const selected = index === copyInd || hoveredIndex === copyInd;
      toRender.push(
        <Bar
          key={copyInd + "_" + index}
          profileImageSize={profileImageSize}
          backgroundColor={colorPalatte[copyInd]}
          onMouseDown={() => {
            if (copyInd === index) updateIndex(-1);
            else updateIndex(copyInd);
          }}
          onMouseEnter={() => {
            if (!/Android|iPhone/i.test(navigator.userAgent)) {
              // This checks if the current device is in fact mobile
              setHoveredIndex(copyInd);
            }
          }}
          onMouseLeave={() => setHoveredIndex(-1)}
          selected={selected}>
          <Text selected={index === copyInd}>{texts[copyInd]}</Text>
        </Bar>
      );
    }
    setChildren(toRender);
  }, [pastVote, index, hoveredIndex]);

  const texts = [
    "Win easily",
    "Probably win",
    "50/50 win/lose",
    "Probably lose",
    "Lose easily",
  ];

  return <Wrapper>{children}</Wrapper>;
}
