import React, { useState } from "react";
import styled from "styled-components/macro";
import { addAnimal } from "../DBStuff/FirebaseTest";

const Wrapper = styled.div``;

export default function MainPage({}) {
  const [animalName, setAnimalName] = useState("");
  const [nlVote, setNLVote] = useState("");
  function submit() {
    const parsedVote = parseInt(nlVote);
    if (
      animalName.length > 0 &&
      parsedVote >= 0 &&
      parsedVote <= 4 &&
      nlVote !== ""
    ) {
      addAnimal(animalName, parsedVote);
      setAnimalName("");
      // setNLVote("");
    } else {
      alert("something was wrong with the inputed data");
    }
  }
  return (
    <Wrapper>
      <p>0 100% lose, 1 probably lose, 2 5050, 3, probably win, 4 100% win</p>
      <p>
        lobster parrot small bird dog goat goose meerkat wolf octopus cow
        dolphin komodo dragon rhinno chimp shark elephant
      </p>
      <p>Animal name</p>
      <input
        autoFocus
        value={animalName}
        onChange={(e) => setAnimalName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") submit();
        }}
      />
      <p>Nls vote</p>
      <input
        value={nlVote}
        onChange={(e) => setNLVote(e.target.value)}
      />
      <p
        style={{
          border: "2px solid red",
          width: "fit-content",
          margin: "auto",
          marginTop: "10px",
          cursor: "pointer",
        }}
        onMouseDown={submit}>
        Submit
      </p>
    </Wrapper>
  );
}
