import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components/macro";
import { animalAttributionMap, animalImageMap } from "../animalImagesMap";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

//Will make an array of name img pairs.
//When you go right or left will start to request the new one while fading out the old image and fading in the new one.
//I think there will be a div over the top of the graph stuff that will fade in then out on top of the graph stuff to hide

//Actually will just ignore that, make it automatic and just have the api calls be instant and stuff. then will see

const CaroselImage = styled.img`
  object-fit: cover;
  height: 275px;
  width: 275px;
`;

const Attribution = styled.p`
  color: white;
  font-size: 13px;
  margin: 15px 0px 5px 0px;
`;

const Title = styled.h1`
  font-size: 38px;
  color: white;
  margin: 15px 0px;
`;

export default function Carosel({
  imgSrc,
  leftImgSrc,
  rightImgSrc,
  attribution,
  animalName,
  leftCallback,
  rightCallback,
  queuedImages,
}) {
  return (
    <div>
      <Title>
        Who would win versus a
        {["A", "E", "I", "O", "U"].includes(animalName[0]) ? "n" : ""}{" "}
        {animalName.toLowerCase()}?
      </Title>
      <Wrapper>
        <Nexter
          mouseDownCallback={leftCallback}
          isLeft
        />
        {queuedImages.map((x, i) => (
          <CaroselImage
            src={x}
            key={i}
            style={{ display: "none" }}
          />
        ))}
        <CaroselImage src={imgSrc} />

        <Nexter mouseDownCallback={rightCallback} />
      </Wrapper>
      <Attribution>{attribution}</Attribution>
    </div>
  );
}

function Nexter({ mouseDownCallback, isLeft = false }) {
  const leftKeyCode = 37;
  const rightKeyCode = 39;
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === (isLeft ? leftKeyCode : rightKeyCode))
        mouseDownCallback();
    }

    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [mouseDownCallback]);
  return (
    <p
      style={{
        fontSize: "160px",
        color: "white",
        margin: "0",
        height: "fit-content",
        cursor: "pointer",
        userSelect: "none",
      }}
      onMouseDown={mouseDownCallback}>
      {isLeft ? "‹" : "›"}
    </p>
  );
}
